export const userConstants = {
  GET_STATE_REQUEST: 'USER_GET_STATE_REQUEST',
  GET_STATE_SUCCESS: 'USER_GET_STATE_SUCCESS',
  GET_STATE_FAILURE: 'USER_GET_STATE_FAILURE',

  SEND_LOCATION_DATA_REQUEST: 'USER_SEND_LOCATION_DATA_REQUEST',
  SEND_LOCATION_DATA_SUCCESS: 'USER_SEND_LOCATION_DATA_SUCCESS',
  SEND_LOCATION_DATA_FAILURE: 'USER_SEND_LOCATION_DATA_FAILURE',

  SEND_APPLE_HEARTRATE_DATA_REQUEST: 'USER_SEND_APPLE_HEARTRATE_DATA_REQUEST',
  SEND_APPLE_HEARTRATE_DATA_SUCCESS: 'USER_SEND_APPLE_HEARTRATE_DATA_SUCCESS',
  SEND_APPLE_HEARTRATE_DATA_FAILURE: 'USER_SEND_APPLE_HEARTRATE_DATA_FAILURE',

  CREATE_LOCATION_APP_REQUEST: 'USER_CREATE_LOCATION_APP_REQUEST',
  CREATE_LOCATION_APP_SUCCESS: 'USER_CREATE_LOCATION_APP_SUCCESS',
  CREATE_LOCATION_APP_FAILURE: 'USER_CREATE_LOCATION_APP_FAILURE',

  CREATE_APPLE_HEALTH_APP_REQUEST: 'USER_CREATE_APPLE_HEALTH_APP_REQUEST',
  CREATE_APPLE_HEALTH_APP_SUCCESS: 'USER_CREATE_APPLE_HEALTH_APP_SUCCESS',
  CREATE_APPLE_HEALTH_APP_FAILURE: 'USER_CREATE_APPLE_HEALTH_APP_FAILURE',

  CONNECT_APP_REQUEST: 'USER_CONNECT_APP_REQUEST',
  CONNECT_APP_SUCCESS: 'USER_CONNECT_APP_SUCCESS',
  CONNECT_APP_FAILURE: 'USER_CONNECT_APP_FAILURE',

  DELETE_POST_REQUEST: 'USER_DELETE_POST_REQUEST',
  DELETE_POST_SUCCESS: 'USER_DELETE_POST_SUCCESS',
  DELETE_POST_FAILURE: 'USER_DELETE_POST_FAILURE',

  GET_LINK_TOKEN_REQUEST: 'USER_GET_LINK_TOKEN_REQUEST',
  GET_LINK_TOKEN_SUCCESS: 'USER_GET_LINK_TOKEN_SUCCESS',
  GET_LINK_TOKEN_FAILURE: 'USER_GET_LINK_TOKEN_FAILURE',

  GET_MORE_POSTS_REQUEST: 'USER_GET_MORE_POSTS_REQUEST',
  GET_MORE_POSTS_SUCCESS: 'USER_GET_MORE_POSTS_SUCCESS',
  GET_MORE_POSTS_FAILURE: 'USER_GET_MORE_POSTS_FAILURE',

  HANDLE_PLAID_RESPONSE_REQUEST: 'USER_HANDLE_PLAID_RESPONSE_REQUEST',
  HANDLE_PLAID_RESPONSE_SUCCESS: 'USER_HANDLE_PLAID_RESPONSE_SUCCESS',
  HANDLE_PLAID_RESPONSE_FAILURE: 'USER_HANDLE_PLAID_RESPONSE_FAILURE',

  UPDATE_ACTIVE_STATUS_REQUEST: 'USER_UPDATE_ACTIVE_STATUS_REQUEST',
  UPDATE_ACTIVE_STATUS_SUCCESS: 'USER_UPDATE_ACTIVE_STATUS_SUCCESS',
  UPDATE_ACTIVE_STATUS_FAILURE: 'USER_UPDATE_ACTIVE_STATUS_FAILURE',

  UPDATE_PROFILE_PICTURE_URL_REQUEST: 'USER_UPDATE_PROFILE_PICTURE_URL_REQUEST',
  UPDATE_PROFILE_PICTURE_URL_SUCCESS: 'USER_UPDATE_PROFILE_PICTURE_URL_SUCCESS',
  UPDATE_PROFILE_PICTURE_URL_FAILURE: 'USER_UPDATE_PROFILE_PICTURE_URL_FAILURE',

  JUMP_TO_DATE_REQUEST: 'USER_JUMP_TO_DATE_REQUEST',
  JUMP_TO_DATE_SUCCESS: 'USER_JUMP_TO_DATE_SUCCESS',
  JUMP_TO_DATE_FAILURE: 'USER_JUMP_TO_DATE_FAILURE',

  NEW_SOURCE_REQUEST: 'USER_NEW_SOURCE_REQUEST',
  NEW_SOURCE_SUCCESS: 'USER_NEW_SOURCE_SUCCESS',
  NEW_SOURCE_FAILURE: 'USER_NEW_SOURCE_FAILURE',

  DELETE_APP_REQUEST: 'USER_DELETE_APP_REQUEST',
  DELETE_APP_SUCCESS: 'USER_DELETE_APP_SUCCESS',
  DELETE_APP_FAILURE: 'USER_DELETE_APP_FAILURE',

  TOGGLE_APP_OPTION_REQUEST: 'USER_TOGGLE_APP_OPTION_REQUEST',
  TOGGLE_APP_OPTION_SUCCESS: 'USER_TOGGLE_APP_OPTION_SUCCESS',
  TOGGLE_APP_OPTION_FAILURE: 'USER_TOGGLE_APP_OPTION_FAILURE',

  GET_METADATA_REQUEST: 'USER_GET_METADATA_REQUEST',
  GET_METADATA_SUCCESS: 'USER_GET_METADATA_SUCCESS',
  GET_METADATA_FAILURE: 'USER_GET_METADATA_FAILURE',

  GET_UPLOAD_URL_REQUEST: 'USER_GET_UPLOAD_URL_REQUEST',
  GET_UPLOAD_URL_SUCCESS: 'USER_GET_UPLOAD_URL_SUCCESS',
  GET_UPLOAD_URL_FAILURE: 'USER_GET_UPLOAD_URL_FAILURE',

  UPLOAD_RECEIPT_REQUEST: 'USER_UPLOAD_RECEIPT_REQUEST',
  UPLOAD_RECEIPT_SUCCESS: 'USER_UPLOAD_RECEIPT_SUCCESS',
  UPLOAD_RECEIPT_FAILURE: 'USER_UPLOAD_RECEIPT_FAILURE',

  SUBMIT_SURVEY_RESPONSE_REQUEST: 'USER_SUBMIT_SURVEY_RESPONSE_REQUEST',
  SUBMIT_SURVEY_RESPONSE_SUCCESS: 'USER_SUBMIT_SURVEY_RESPONSE_SUCCESS',
  SUBMIT_SURVEY_RESPONSE_FAILURE: 'USER_SUBMIT_SURVEY_RESPONSE_FAILURE',

  LINK_POST_TO_LOCATION_REQUEST: 'USER_LINK_POST_TO_LOCATION_REQUEST',
  LINK_POST_TO_LOCATION_SUCCESS: 'USER_LINK_POST_TO_LOCATION_SUCCESS',
  LINK_POST_TO_LOCATION_FAILURE: 'USER_LINK_POST_TO_LOCATION_FAILURE',

  MARK_NOTIFICATION_AS_ACCEPTED_REQUEST: 'USER_MARK_NOTIFICATION_AS_ACCEPTED_REQUEST',
  MARK_NOTIFICATION_AS_ACCEPTED_SUCCESS: 'USER_MARK_NOTIFICATION_AS_ACCEPTED_SUCCESS',
  MARK_NOTIFICATION_AS_ACCEPTED_FAILURE: 'USER_MARK_NOTIFICATION_AS_ACCEPTED_FAILURE',

  UPDATE_TIMELINE_ORIENTATION_REQUEST: 'USER_UPDATE_TIMELINE_ORIENTATION_REQUEST',
  UPDATE_TIMELINE_ORIENTATION_SUCCESS: 'USER_UPDATE_TIMELINE_ORIENTATION_SUCCESS',
  UPDATE_TIMELINE_ORIENTATION_FAILURE: 'USER_UPDATE_TIMELINE_ORIENTATION_FAILURE',

  UPDATE_TIMELINE_INTERFACE_REQUEST: 'USER_UPDATE_TIMELINE_INTERFACE_REQUEST',
  UPDATE_TIMELINE_INTERFACE_SUCCESS: 'USER_UPDATE_TIMELINE_INTERFACE_SUCCESS',
  UPDATE_TIMELINE_INTERFACE_FAILURE: 'USER_UPDATE_TIMELINE_INTERFACE_FAILURE',

  UPDATE_TIMELINE_BETA_REQUEST: 'USER_UPDATE_TIMELINE_BETA_REQUEST',
  UPDATE_TIMELINE_BETA_SUCCESS: 'USER_UPDATE_TIMELINE_BETA_SUCCESS',
  UPDATE_TIMELINE_BETA_FAILURE: 'USER_UPDATE_TIMELINE_BETA_FAILURE',

  UPDATE_TEMPERATURE_PREFERENCE_REQUEST: 'USER_UPDATE_TEMPERATURE_PREFERENCE_REQUEST',
  UPDATE_TEMPERATURE_PREFERENCE_SUCCESS: 'USER_UPDATE_TEMPERATURE_PREFERENCE_SUCCESS',
  UPDATE_TEMPERATURE_PREFERENCE_FAILURE: 'USER_UPDATE_TEMPERATURE_PREFERENCE_FAILURE',

  UPDATE_WEATHER_DISPLAY_REQUEST: 'USER_UPDATE_WEATHER_DISPLAY_REQUEST',
  UPDATE_WEATHER_DISPLAY_SUCCESS: 'USER_UPDATE_WEATHER_DISPLAY_SUCCESS',
  UPDATE_WEATHER_DISPLAY_FAILURE: 'USER_UPDATE_WEATHER_DISPLAY_FAILURE',

  UPDATE_HEARTRATE_DISPLAY_REQUEST: 'USER_UPDATE_HEARTRATE_DISPLAY_REQUEST',
  UPDATE_HEARTRATE_DISPLAY_SUCCESS: 'USER_UPDATE_HEARTRATE_DISPLAY_SUCCESS',
  UPDATE_HEARTRATE_DISPLAY_FAILURE: 'USER_UPDATE_HEARTRATE_DISPLAY_FAILURE',

  UPDATE_LOGIN_PREFERENCE_REQUEST: 'USER_UPDATE_LOGIN_PREFERENCE_REQUEST',
  UPDATE_LOGIN_PREFERENCE_SUCCESS: 'USER_UPDATE_LOGIN_PREFERENCE_SUCCESS',
  UPDATE_LOGIN_PREFERENCE_FAILURE: 'USER_UPDATE_LOGIN_PREFERENCE_FAILURE',

  UPDATE_ACCOUNT_PREFERENCE_REQUEST: 'USER_UPDATE_ACCOUNT_PREFERENCE_REQUEST',
  UPDATE_ACCOUNT_PREFERENCE_SUCCESS: 'USER_UPDATE_ACCOUNT_PREFERENCE_SUCCESS',
  UPDATE_ACCOUNT_PREFERENCE_FAILURE: 'USER_UPDATE_ACCOUNT_PREFERENCE_FAILURE',

  UPDATE_SELECTED_API_IDS_REQUEST: 'USER_UPDATE_SELECTED_API_IDS_REQUEST',
  UPDATE_SELECTED_API_IDS_SUCCESS: 'USER_UPDATE_SELECTED_API_IDS_SUCCESS',
  UPDATE_SELECTED_API_IDS_FAILURE: 'USER_UPDATE_SELECTED_API_IDS_FAILURE',

  UPDATE_APNS_TOKEN_REQUEST: 'USER_UPDATE_APNS_TOKEN_REQUEST',
  UPDATE_APNS_TOKEN_SUCCESS: 'USER_UPDATE_APNS_TOKEN_SUCCESS',
  UPDATE_APNS_TOKEN_FAILURE: 'USER_UPDATE_APNS_TOKEN_FAILURE',

  UPDATE_WALLET_DATA_REQUEST: 'USER_UPDATE_WALLET_DATA_REQUEST',
  UPDATE_WALLET_DATA_SUCCESS: 'USER_UPDATE_WALLET_DATA_SUCCESS',
  UPDATE_WALLET_DATA_FAILURE: 'USER_UPDATE_WALLET_DATA_FAILURE',

  UPDATE_LAST_NOTIFICATION_ID_REQUEST: 'USER_UPDATE_LAST_NOTIFICATION_ID_REQUEST',
  UPDATE_LAST_NOTIFICATION_ID_SUCCESS: 'USER_UPDATE_LAST_NOTIFICATION_ID_SUCCESS',
  UPDATE_LAST_NOTIFICATION_ID_FAILURE: 'USER_UPDATE_LAST_NOTIFICATION_ID_FAILURE',

  UPDATE_ONBOARDING_STATUS_REQUEST: 'USER_UPDATE_ONBOARDING_STATUS_REQUEST',
  UPDATE_ONBOARDING_STATUS_SUCCESS: 'USER_UPDATE_ONBOARDING_STATUS_SUCCESS',
  UPDATE_ONBOARDING_STATUS_FAILURE: 'USER_UPDATE_ONBOARDING_STATUS_FAILURE',
  UPDATE_ONBOARDING_STATUS_DEMO: 'USER_UPDATE_ONBOARDING_STATUS_DEMO',

  UPDATE_SELECTED_SERVER_REQUEST: 'USER_UPDATE_SELECTED_SERVER_REQUEST',
  UPDATE_SELECTED_SERVER_SUCCESS: 'USER_UPDATE_SELECTED_SERVER_SUCCESS',
  UPDATE_SELECTED_SERVER_FAILURE: 'USER_UPDATE_SELECTED_SERVER_FAILURE',

  VALIDATE_STEAMID_REQUEST:'USER_VALIDATE_STEAMID_REQUEST',
  VALIDATE_STEAMID_SUCCESS:'USER_VALIDATE_STEAMID_SUCCESS',
  VALIDATE_STEAMID_FAILURE:'USER_VALIDATE_STEAMID_FAILURE',

  AUTHORIZE_STEAM_REQUEST:'USER_AUTHORIZE_STEAM_REQUEST',
  AUTHORIZE_STEAM_SUCCESS:'USER_AUTHORIZE_STEAM_SUCCESS',
  AUTHORIZE_STEAM_FAILURE:'USER_AUTHORIZE_STEAM_FAILURE',

  CHECK_IP_REQUEST:'USER_CHECK_IP_REQUEST',
  CHECK_IP_SUCCESS:'USER_CHECK_IP_SUCCESS',
  CHECK_IP_FAILURE:'USER_CHECK_IP_FAILURE',

  GET_ELECTRON_KEYS_REQUEST:'USER_GET_ELECTRON_KEYS_REQUEST',
  GET_ELECTRON_KEYS_SUCCESS:'USER_GET_ELECTRON_KEYS_SUCCESS',
  GET_ELECTRON_KEYS_FAILURE:'USER_GET_ELECTRON_KEYS_FAILURE',

  GET_SUBSCRIPTIONS_REQUEST:'USER_GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_SUCCESS:'USER_GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_FAILURE:'USER_GET_SUBSCRIPTIONS_FAILURE',

  CREATE_CONNECTION_CREDENTIALS_REQUEST:'USER_CREATE_CONNECTION_CREDENTIALS_REQUEST',
  CREATE_CONNECTION_CREDENTIALS_SUCCESS:'USER_CREATE_CONNECTION_CREDENTIALS_SUCCESS',
  CREATE_CONNECTION_CREDENTIALS_FAILURE:'USER_CREATE_CONNECTION_CREDENTIALS_FAILURE',

  CONNECT_TO_NODE_REQUEST:'USER_CONNECT_TO_NODE_REQUEST',
  CONNECT_TO_NODE_SUCCESS:'USER_CONNECT_TO_NODE_SUCCESS',
  CONNECT_TO_NODE_FAILURE:'USER_CONNECT_TO_NODE_FAILURE',

  DISCONNECT_FROM_NODE_REQUEST:'USER_DISCONNECT_FROM_NODE_REQUEST',
  DISCONNECT_FROM_NODE_SUCCESS:'USER_DISCONNECT_FROM_NODE_SUCCESS',
  DISCONNECT_FROM_NODE_FAILURE:'USER_DISCONNECT_FROM_NODE_FAILURE',

  RESPOND_TO_OFFER_REQUEST:'USER_RESPOND_TO_OFFER_REQUEST',
  RESPOND_TO_OFFER_SUCCESS:'USER_RESPOND_TO_OFFER_SUCCESS',
  RESPOND_TO_OFFER_FAILURE:'USER_RESPOND_TO_OFFER_FAILURE',
  RESPOND_TO_OFFER_DEMO:'USER_RESPOND_TO_OFFER_DEMO',

  QUERY_BREADCRUMBS_REQUEST:'USER_QUERY_BREADCRUMBS_REQUEST',
  QUERY_BREADCRUMBS_SUCCESS:'USER_QUERY_BREADCRUMBS_SUCCESS',
  QUERY_BREADCRUMBS_FAILURE:'USER_QUERY_BREADCRUMBS_FAILURE',

  UPDATE_APP_STORE_PURCHASE_STATUS_REQUEST:'USER_UPDATE_APP_STORE_PURCHASE_STATUS_REQUEST',
  UPDATE_APP_STORE_PURCHASE_STATUS_SUCCESS:'USER_UPDATE_APP_STORE_PURCHASE_STATUS_SUCCESS',
  UPDATE_APP_STORE_PURCHASE_STATUS_FAILURE:'USER_UPDATE_APP_STORE_PURCHASE_STATUS_FAILURE',

  CREATE_OFFER_REQUEST:'USER_CREATE_OFFER_REQUEST',
  CREATE_OFFER_SUCCESS:'USER_CREATE_OFFER_SUCCESS',
  CREATE_OFFER_FAILURE:'USER_CREATE_OFFER_FAILURE',

  GENERATE_BCRK_REQUEST:'USER_GENERATE_BCRK_REQUEST',
  GENERATE_BCRK_SUCCESS:'USER_GENERATE_BCRK_SUCCESS',
  GENERATE_BCRK_FAILURE:'USER_GENERATE_BCRK_FAILURE',

  SUBMIT_PROFILE_DETAIL_REQUEST:'USER_SUBMIT_PROFILE_DETAIL_REQUEST',
  SUBMIT_PROFILE_DETAIL_SUCCESS:'USER_SUBMIT_PROFILE_DETAIL_SUCCESS',
  SUBMIT_PROFILE_DETAIL_FAILURE:'USER_SUBMIT_PROFILE_DETAIL_FAILURE',
  SUBMIT_PROFILE_DETAIL_DEMO:'USER_SUBMIT_PROFILE_DETAIL_DEMO',

  ASK_LLM_REQUEST:'USER_ASK_LLM_REQUEST',
  ASK_LLM_SUCCESS:'USER_ASK_LLM_SUCCESS',
  ASK_LLM_FAILURE:'USER_ASK_LLM_FAILURE',

  EMBED_REQUEST:'USER_EMBED_REQUEST',
  EMBED_SUCCESS:'USER_EMBED_SUCCESS',
  EMBED_FAILURE:'USER_EMBED_FAILURE',

  PLAID_REAUTH_REQUEST:'USER_PLAID_REAUTH_REQUEST',
  PLAID_REAUTH_SUCCESS:'USER_PLAID_REAUTH_SUCCESS',
  PLAID_REAUTH_FAILURE:'USER_PLAID_REAUTH_FAILURE',

  PERSIST_PAYMENT_REQUEST:'USER_PERSIST_PAYMENT_REQUEST',
  PERSIST_PAYMENT_SUCCESS:'USER_PERSIST_PAYMENT_SUCCESS',
  PERSIST_PAYMENT_FAILURE:'USER_PERSIST_PAYMENT_FAILURE',

  SUBMIT_FEEDBACK_REQUEST:'USER_SUBMIT_FEEDBACK_REQUEST',
  SUBMIT_FEEDBACK_SUCCESS:'USER_SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_FAILURE:'USER_SUBMIT_FEEDBACK_FAILURE',

  GET_RECENT_LOCATION_DATA_REQUEST:'USER_GET_RECENT_LOCATION_DATA_REQUEST',
  GET_RECENT_LOCATION_DATA_SUCCESS:'USER_GET_RECENT_LOCATION_DATA_SUCCESS',
  GET_RECENT_LOCATION_DATA_FAILURE:'USER_GET_RECENT_LOCATION_DATA_FAILURE',

  ADD_POLYLINES_REQUEST: 'USER_ADD_POLYLINES_REQUEST',
  ADD_POLYLINES_SUCCESS: 'USER_ADD_POLYLINES_SUCCESS',
  ADD_POLYLINES_FAILURE: 'USER_ADD_POLYLINES_FAILURE',
  ADD_POLYLINES_UPDATE: 'USER_ADD_POLYLINES_UPDATE',

  UPDATE_INFO_DISPLAY_REQUEST: 'USER_UPDATE_INFO_DISPLAY_REQUEST',
  ADD_VPN_SIDEBAR_HEIGHTS_UPDATE: 'USER_ADD_VPN_SIDEBAR_HEIGHTS_UPDATE',
  ADD_SIDEBAR_HEIGHTS_UPDATE: 'USER_ADD_SIDEBAR_HEIGHTS_UPDATE',

  ASK_ATLAS_REQUEST:'USER_ASK_ATLAS_REQUEST',
  ASK_ATLAS_SUCCESS:'USER_ASK_ATLAS_SUCCESS',
  ASK_ATLAS_FAILURE:'USER_ASK_ATLAS_FAILURE',

  UPDATE_PROFILE_REQUEST:'USER_UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS:'USER_UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE:'USER_UPDATE_PROFILE_FAILURE',
};
